var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.dataForm)?_c('b-modal',{ref:"modal-save-form-ref",attrs:{"title":"Klor Oranı","ok-title":"Kaydet","cancel-title":"Kapat","cancel-variant":"outline-secondary","no-close-on-backdrop":"","no-enforce-focus":"","centered":"","size":"lg"},on:{"hidden":_vm.hiddenModal,"ok":_vm.handleOk}},[_c('validation-observer',{ref:"saveFormRef"},[_c('b-form',{ref:"form",on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.saveForm($event)}}},[_c('b-row',[_c('b-col',{staticClass:"mb-md-0 mb-2",attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":"Kodu"}},[_c('validation-provider',{attrs:{"rules":"required","name":"sKodu"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text"},model:{value:(_vm.dataForm.sKodu),callback:function ($$v) {_vm.$set(_vm.dataForm, "sKodu", $$v)},expression:"dataForm.sKodu"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1010200718)})],1)],1),_c('b-col',{staticClass:"mb-md-0 mb-2",attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":"Adı"}},[_c('validation-provider',{attrs:{"rules":"required","name":"sAdi"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text"},model:{value:(_vm.dataForm.sAdi),callback:function ($$v) {_vm.$set(_vm.dataForm, "sAdi", $$v)},expression:"dataForm.sAdi"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1888215991)})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mb-md-0 mb-2",attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Standart Adı"}},[_c('validation-provider',{attrs:{"rules":"required","name":"sStandartName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text"},model:{value:(_vm.dataForm.sStandartName),callback:function ($$v) {_vm.$set(_vm.dataForm, "sStandartName", $$v)},expression:"dataForm.sStandartName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3346160567)})],1)],1),_c('b-col',{staticClass:"mb-md-0 mb-2",attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"","label-for":"contact-options","label-class":"mb-1"}},[_c('div',{staticClass:"demo-inline-spacing"},[_c('b-form-checkbox',{staticClass:"custom-control-primary",model:{value:(_vm.dataForm.isActive),callback:function ($$v) {_vm.$set(_vm.dataForm, "isActive", $$v)},expression:"dataForm.isActive"}},[_vm._v(" "+_vm._s(_vm.$t("Aktif Durumu"))+" ")])],1)])],1)],1),_c('b-row',[_c('b-col',{staticClass:"mb-md-0 mb-2",attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":"Klor Oranı"}},[_c('validation-provider',{attrs:{"rules":"required","name":"ldOrani"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"min":"0","step":"any","type":"number"},model:{value:(_vm.dataForm.ldOrani),callback:function ($$v) {_vm.$set(_vm.dataForm, "ldOrani", $$v)},expression:"dataForm.ldOrani"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4032150395)})],1)],1)],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }