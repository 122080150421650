<template>
  <!-- select 2 demo -->
  <div>
    <b-modal
      ref="modal-save-form-ref"
      title="Klor Oranı"
      ok-title="Kaydet"
      cancel-title="Kapat"
      cancel-variant="outline-secondary"
      no-close-on-backdrop
      no-enforce-focus
      centered
      size="lg"
      v-if="dataForm"
      @hidden="hiddenModal"
      @ok="handleOk"
    >
      <validation-observer ref="saveFormRef">
        <b-form ref="form" @submit.stop.prevent="saveForm">
          <b-row>
            <b-col cols="12" md="12" class="mb-md-0 mb-2">
              <b-form-group label="Kodu">
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="sKodu"
                >
                  <b-form-input
                    v-model="dataForm.sKodu"
                    type="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="12" class="mb-md-0 mb-2">
              <b-form-group label="Adı">
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="sAdi"
                >
                  <b-form-input
                    v-model="dataForm.sAdi"
                    type="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" md="6" class="mb-md-0 mb-2">
              <b-form-group label="Standart Adı">
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="sStandartName"
                >
                  <b-form-input
                    v-model="dataForm.sStandartName"
                    type="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6" class="mb-md-0 mb-2">
              <b-form-group label="" label-for="contact-options" label-class="mb-1">
                <div class="demo-inline-spacing">
                  <b-form-checkbox
                    v-model="dataForm.isActive"
                    class="custom-control-primary"
                  >
                    {{ $t("Aktif Durumu") }}
                  </b-form-checkbox>
                </div>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" md="12" class="mb-md-0 mb-2">
              <b-form-group label="Klor Oranı">
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="ldOrani"
                >
                  <b-form-input
                    v-model="dataForm.ldOrani"
                    min="0" step="any"
                    type="number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import { required } from '@validations';
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import AlertService from '@/common/alert.service'
import ApiService from '@/common/api.service'

// Toast
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    modalObject: {
      type: Object,
       default() {
        return {}
      },
    },
    callback: {
      type: Function,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      required,
      dataForm: {
        sKodu: null,
        sAdi: null,
        sStandartName: null,
        isActive: true,
        ldOrani: 0,
      },
    }
  },
  methods: {
    saveForm() {
      this.$refs.saveFormRef.validate().then(success => {
        if (success) {
          ApiService.post('manage/kalite/klororani/save', this.dataForm, this)
          .then(response => {
            if (response.statusCode === 200) {
              this.$toast({
                component: ToastificationSuccess,
                position: 'top-right',
                props: { text: response.message },
              })
              this.modalObject.onSuccess();
              this.hiddenModal();
            } else {
              AlertService.error(this, response.message)
            }
          });
        }
      });
    },

    initialModal() {
      if (this.modalObject.item) {
        this.dataForm = this.modalObject.item;
      }
      this.$refs['modal-save-form-ref'].show();
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.saveForm()
    },
    hiddenModal() {
      this.$refs['modal-save-form-ref'].hide()
      this.modalObject.showModal = false;
    },
  },
  mounted() {
    this.initialModal()
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
